import React from 'react';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';

import ContactPage from './pages/ContactPage';
import FAQPage from './pages/FAQPage';
import GalleryPage from './pages/GalleryPage';
import HomePage from './pages/HomePage';
import OrderNowPage from './pages/OrderNow';
import ProductsPage from './pages/ProductsPage';
import './App.css';

const App = () => {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />
    },
    {
      path: '/order-now',
      element: <OrderNowPage />
    },
    {
      path: '/products',
      element: <ProductsPage />
    },
    {
      path: '/gallery',
      element: <GalleryPage />
    },
    {
      path: '/faq',
      element: <FAQPage />
    },
    {
      path: '/contact',
      element: <ContactPage />
    }
  ]);


  return (
    <div className="App">
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
