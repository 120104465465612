import { Row, Col } from "react-bootstrap";
import styles from './Gallery.module.css';

const Gallery = () => {

    const galleryItems = [
        { id: 1, imageUrl: 'https://lilysbakes.com/img/gallery/1.jpeg', description: 'Cupcakes' },
        { id: 2, imageUrl: 'https://lilysbakes.com/img/gallery/2.jpeg', description: 'Og baby cake' },
        { id: 3, imageUrl: 'https://lilysbakes.com/img/gallery/3.jpeg', description: 'Tiered cake' },
        { id: 4, imageUrl: 'https://lilysbakes.com/img/gallery/4.jpeg', description: 'Oh baby cake' },
        { id: 5, imageUrl: 'https://lilysbakes.com/img/gallery/5.jpeg', description: 'Cupcakes' },
        { id: 6, imageUrl: 'https://lilysbakes.com/img/gallery/6.jpeg', description: 'Tiered cake' },
        { id: 7, imageUrl: 'https://lilysbakes.com/img/gallery/8.jpeg', description: 'Cupcakes' },
        { id: 8, imageUrl: 'https://lilysbakes.com/img/gallery/10.jpeg', description: 'Cupcakes' },
        { id: 9, imageUrl: 'https://lilysbakes.com/img/gallery/11.jpeg', description: 'Cupcakes' },
        { id: 10, imageUrl: 'https://lilysbakes.com/img/gallery/12.jpeg', description: 'Oreo cupcakes' }
    ];

    return (
        <Row>
            {galleryItems.map((item, index) => (
                <Col xs={12} sm={4} key={index} style={{ marginBottom: '20px' }}>
                    <div className={styles.galleryImageOuterContainer}>
                        <div className={styles.galleryImageContainer}>
                            <img src={item.imageUrl} className={styles.galleryImage} alt={item.description} />
                    </div>
                    </div>
                    
                </Col>
            ))}
        </Row>
    );
}

export default Gallery;