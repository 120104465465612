import { useState } from 'react';
import { Container, Row, Col, Form, Modal, Button } from 'react-bootstrap';
import styles from './Products.module.css';

const Product = props => {

    const [differentColours, setDifferentColours] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    return (
        <div className={styles.detailedProductContainer}>
            <Container>
                <Row>
                    <Col sm={4}>
                        <div className={styles.detailedProductImageContainer}>
                            <img src={props.placeholderImage} />
                        </div>
                    </Col>
                    <Col sm={8}>
                        <h2>{props.selectedProduct.category_screen_name} | {props.selectedProduct.screen_name} | £{props.selectedProduct.price}</h2>
                        <Form>

                            <Form.Group className={styles.formGroup}>
                                <Form.Label className={styles.contactFormLabel}>Select your cake flavour</Form.Label>
                                <Form.Select aria-label="Cake flavour select">
                                    <option>Select cake flavour</option>
                                    <option value="vanilla-sponge">Vanilla sponge</option>
                                    <option value="chocolate">Chocolate</option>
                                    <option value="red-velvet">Red velvet</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className={styles.formGroup}>
                                <Form.Label>Select your icing colour</Form.Label>
                                <Form.Select aria-label="Colour select">
                                    <option>Select colour</option>
                                    {props.selectedProduct.colours.map(colour => (
                                        <option value={colour} key={colour}>{colour}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Different border colour?</Form.Label>

                                <div key={`inline-radio`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="No thanks - Same colour as cake"
                                        name="same-as-cake"
                                        type="radio"
                                        id={`inline-radio-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="Yes"
                                        name="different-colour-icing"
                                        type="radio"
                                        id={`inline-radio-2`}
                                    />
                                </div>
                            </Form.Group>

                            {differentColours === true ?
                                <Form.Group className={styles.formGroup}>
                                    <Form.Select aria-label="Colour select">
                                        <option>Select border colour</option>
                                        {props.selectedProduct.colours.map(colour => (
                                            <option value={colour}>{colour}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                :
                                ''
                            }

                            <Form.Group className="mb-3" controlId="custom-message-input">
                                <Form.Label>Custom message</Form.Label>
                                <Form.Control type="text" placeholder="Enter custom message" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Lettering type</Form.Label>

                                <div key={`inline-radio`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="Uppercase"
                                        name="uppercase"
                                        type="radio"
                                        id={`inline-radio-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="Handwritten"
                                        name="handwritten"
                                        type="radio"
                                        id={`inline-radio-2`}
                                    />
                                </div>
                            </Form.Group>


                        </Form>
                        <button onClick={handleShow} name={props.selectedProduct.name} className={styles.lilysButton}>Place order</button>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Contact details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please add your contact details and we'll be in touch about your order</p>
                    <Form>
                        <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                            <Form.Label className={styles.contactFormLabel}>Name</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>

                        <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                            <Form.Label>Telephone number</Form.Label>
                            <Form.Control type="tel" />
                        </Form.Group>

                        <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" />
                        </Form.Group>

                        <button type="submit" className={styles.lilysButton}>
                            Send order request
                        </button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Product;