import logo from '../../img/logo.jpeg';
import Navigation from '../Nav/Navigation';
import styles from './Header.module.css';

const Header = () => {


    return (
        <div className={styles.header}>
            <img src={logo} className={styles.logo} alt="Lily's bakes logo" />
            <Navigation />
        </div>
    );
}

export default Header;