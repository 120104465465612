import Gallery from "../components/Gallery/Gallery";
import Header from "../components/Header/Header";
import styles from './Pages.module.css';

const GalleryPage = () => {


    return (
        <div className={styles.page}>
            <Header />
            <h1>Gallery</h1>
            <div>
                <Gallery />
            </div>
        </div>
    );
}

export default GalleryPage;