import Header from "../components/Header/Header";
import { Accordion, Container, Row, Col } from "react-bootstrap";
import styles from './Pages.module.css';

const FAQPage = () => {


    return (
        <div className={styles.page}>
            <Header />
            <h1>FAQ</h1>

            <Container fluid>
                <Row>
                    <Col lg={{ offset: 1, span: 10 }}>

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className={styles.accordionHeader}>Do you deliver?</Accordion.Header>
                                <Accordion.Body>
                                We can arrange a courier delivery if you are unable to collect or want to send cakes/cupcakes as a gift across the country. However, cost of courier will be quoted and paid for by customer.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What size cakes and cupcake boxes do you do?</Accordion.Header>
                                <Accordion.Body>
                                Our round cakes start from 4 inch, 5 inch, 8 inch and 10 inch. We also offer 5 inch and 8 inch heart shaped cakes. 
Cupcakes are in boxes of 6 or 12. 
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How do you package Lily's bakes?</Accordion.Header>
                                <Accordion.Body>
                                All cakes and cupcakes are packaged in white boxes with beautiful ribbon with Lily's thank you cards.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Do you do corporate/events gifting?</Accordion.Header>
                                <Accordion.Body>
                                We do indeed. Drop us an email and we would be happy to help and advise on what we can do for your company or event. Including personalised logos/messages etc.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Do you cater for any allergies or dietary requirements?</Accordion.Header>
                                <Accordion.Body>
                                Being Coeliac myself, we most definitely offer gluten free and dairy free options. We however cannot guarantee zero contamination as cakes are made in a kitchen where gluten and diary are being handled. 
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Can you do personalised messaging on cakes and cupcakes?</Accordion.Header>
                                <Accordion.Body>
                                Yes, from personalised logos to names and messages or fondant figures just specify in your order what personalisation you would like and we can advise on options/costs.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>How far in advance should I place an order? </Accordion.Header>
                                <Accordion.Body>
                                For cakes please enquire atleast 1-2 weeks in advance and cupcakes 5-7 days.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default FAQPage;