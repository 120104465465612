import Header from "../components/Header/Header";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import styles from './Pages.module.css';
import { useState } from "react";
import axios from 'axios';
import ReactContactForm from 'react-mail-form';
import { useEffect } from "react";
import { getDatabase, onValue, ref, set } from 'firebase/database';

const API_PATH = 'api/contact/index.php';

const ContactPage = () => {

    const db = getDatabase();

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [mailSent, setMailSent] = useState(false);
    const [error, setError] = useState(null);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const messagesRef = ref(db, "lilysbakes/messages");
        onValue(messagesRef, (snapshot) => {
            const messagesObject = snapshot.val();
            const listOfMessages = Object.keys(messagesObject).map(key => ({
                ...messagesObject[key],
                uid: key
            }));
            setMessages(listOfMessages);
            console.log('Messages... ', listOfMessages.length);
        });
    }, []);

    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onTelChange = (e) => {
        setTel(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const uuid = crypto.randomUUID();

        const newMessageRef = ref(db, `lilysbakes/messages/${uuid}`);
        const form = {
            name: name,
            tel: tel,
            email: email,
            message: message,
            mailSent: false,
            error: null
        }

        set(newMessageRef, form)
            .then(() => {
                setMailSent(true);
            })
            .catch(error => console.log(error));



        console.log('Form: ', form);

    }



    return (
        <div className={styles.page}>
            <Header />
            <h1>Contact</h1>

            <div className={styles.contactFormContainer}>
                <Container fluid>
                    <Row>
                        <Col lg={{ offset: 2, span: 8 }}>
                            <Form>
                                <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                                    <Form.Label className={styles.contactFormLabel}>Name</Form.Label>
                                    <Form.Control type="text" value={name} onChange={e => onNameChange(e)} />
                                </Form.Group>

                                <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                                    <Form.Label>Telephone number</Form.Label>
                                    <Form.Control type="tel" value={tel} onChange={e => onTelChange(e)} />
                                </Form.Group>

                                <Form.Group className={`mb-3 ${styles.contactFormInput}`}>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" value={email} onChange={e => onEmailChange(e)} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={5} value={message} onChange={e => onMessageChange(e)} />
                                </Form.Group>

                                <Button type="submit" className={styles.lilysButton} onClick={handleFormSubmit}>
                                    Send message
                                </Button>
                                <div>
                                    {mailSent &&
                                        <div>Thank you for contacting us.</div>
                                    }
                                </div>
                            </Form>
                        </Col>
                    </Row>

                </Container>
            </div>


        </div>
    );
}

export default ContactPage;