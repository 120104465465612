import styles from './Pages.module.css';
import logo from '../img/logo.jpeg';
import Navigation from "../components/Nav/Navigation";

const HomePage = () => {


    return (
        <div className={styles.page}>
            <Navigation />
            <div className={styles.homepageLogoContainer}>
                <img src={logo} className={styles.logo} alt="Lily's bakes logo" />
            </div>
        </div>
    );
}

export default HomePage;