import { useEffect, useState } from "react";
import { withFirebase } from '../../firebase';
import { Col, Container, Row } from "react-bootstrap";
import _ from 'underscore';
import styles from './Products.module.css';

import placeholderImage from '../../img/placeholder.png';
import Product from "./Product";

const Products = (props) => {

    const [productList, updateProductList] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState({});

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        props.firebase.products().on('value', snapshot => {
            const productListObject = snapshot.val();
            const productList = Object.keys(productListObject).map(key => ({
                ...productListObject[key],
                uid: key,
            }));

            updateProductList(productList);
            setLoadingStatus(false);

        })
    }

    const selectProduct = (e) => {
        const selectedProductObject = _.findWhere(productList, { name: e.target.name });
        setSelectedProduct(selectedProductObject);
    }

    const backToProductsList = () => {
        setSelectedProduct({});
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col xs={2}>
                        {selectedProduct && selectedProduct.name && selectedProduct.name.length > 0 ?
                            <p onClick={backToProductsList}>Back</p>
                            :
                            ''
                        }
                    </Col>
                    <Col xs={8}>
                        <h1>Order now</h1>
                    </Col>
                    <Col xs={12}>
                        {productList && productList.length > 0 ?
                            <div>
                                {selectedProduct && selectedProduct.name && selectedProduct.name.length > 0 ?
                                    <Product selectedProduct={selectedProduct} placeholderImage={placeholderImage} />
                                    :
                                    <div className={styles.productListContainer}>
                                        <Row>
                                            {productList.map((product, i) => (
                                                <Col md={4} lg={4} key={i}>
                                                    <div className={styles.productContainer} >
                                                        <img src={placeholderImage} />
                                                        <h2>{product.category_screen_name} | {product.screen_name}</h2>
                                                        <h3>£{product.price}</h3>
                                                        <button onClick={e => selectProduct(e)} name={product.name} className={styles.lilysButton}>Order</button>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                }

                            </div>
                            :
                            <div>
                                Loading products...
                            </div>
                        }
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default withFirebase(Products);