import { Form } from 'react-bootstrap';
import Header from "../components/Header/Header";
import styles from './Pages.module.css';
import StepWizard from 'react-step-wizard';
import SelectCake from '../Wizards/OrderForm/SelectCake';
import Wizard from '../Wizards/OrderForm/Wizard';
import Products from '../components/Products/Products';
import ProductsPage from './ProductsPage';

const OrderNowPage = () => {

    const onStepChange = (stats) => {
        // console.log(stats);
    };


    return (
        <div className={styles.page}>
            <Header />
            <ProductsPage />
        </div>
    );
}

export default OrderNowPage;